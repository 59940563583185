
    .stu-process-btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 37px;
        .process-btn {
            width: 120px;
            height: 40px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #1122D8;
            .iconfont {
                font-size: 20px;
            }
            color: #1122D8;
            cursor: pointer;
            &.btn-up {
                margin-right: 9px;
                span {
                    margin-left: 8px;
                }
            }
            &.btn-down {
                i.iconfont {
                    transform: rotate(180deg);
                }
                span {
                    margin-right: 8px;
                }
            }
            &.disabled {
                border: 1px solid #E5E5E5;
                color: #E5E5E5;
                cursor: not-allowed;
            }
        }
        i.iconfont {
            font-size: 15px;
        }
    }
    .answer-wrap {
        background: #F2F3FA;
        height: 100vh;
        min-width: 1200px;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 60px;
            background: #fff;
            position: relative;

            .header-left {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                color: #1122D8;
                font-size: 18px;
                margin-left: 40px;
            }

            .header-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-right: 30px;

                .user-info {
                    display: flex;
                    align-items: center;

                    .user-avatar {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        border-radius: 50%;
                        border: 1px solid #eee;
                        box-sizing: border-box;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .user-name {
                        margin-left: 6px;
                    }
                }

                .exit-btn {
                    margin-left: 15px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all .3s;
                    height: 24px;

                    .iconfont {
                        font-size: 20px;
                    }

                    div {
                        height: 24px;
                        line-height: 30px;
                    }

                    &:hover {
                        color: #FD4446;
                    }
                }
            }
        }

        .answer-main {
            margin: 20px;
            height: calc(100% - 100px);
            display: flex;

            .main-item {
                height: 100%;
            }

            .answer-left {
                flex: 1;
                margin-right: 20px;
                background: #FFFFFF;
                box-shadow: 0px 0px 10px 0px rgba(17, 34, 216, 0.1);
                border-radius: 8px;
                display: flex;
                flex-direction: column;

                .exerciseDetail-main {
                    height: 1%;
                    flex: 1;

                    ::v-deep > .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                }
            }

            .answer-right {
                width: 370px;

                .back-btn {
                    height: 40px;

                    .el-button {
                        width: 100%;
                        background: #1122D8;
                        border: 1px solid #E5E5E5;
                        border-radius: 4px;
                        color: #fff;
                    }
                }
            }
        }
    }
    .process-bar {
        width: 100%;
        height: calc(100% - 40px);
        &::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
